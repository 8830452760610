import styled from '@emotion/styled'
import { Separator } from 'app/components/Common/Separator'
import { ContactsIntro } from 'app/components/ContactsIntro'
import { Hero } from 'app/components/Hero'
import { SEO } from 'app/components/SEO'
import { ContactsForm } from 'app/containers/ContactsForm'
import { Footer } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { StructuredData } from 'app/containers/StructuredData'
import {
  ContactsFormSenderBackend,
  ContactsFormSenderBackendConfiguration,
} from 'app/utils/ContactsFormSender'
import { PageProps } from 'gatsby'
import React, { memo, useMemo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id?: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  contactsFormSenderBackendConfiguration: ContactsFormSenderBackendConfiguration
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function ContactsPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  const sendFormToBackend = useMemo(
    () =>
      ContactsFormSenderBackend(
        pageContext.contactsFormSenderBackendConfiguration,
      ),
    [pageContext.contactsFormSenderBackendConfiguration],
  )

  return (
    <Container>
      <SEO {...context.seoProps} />
      <GoogleAnalytics />
      {pageContext.headerProps ? (
        <Header
          pageID={pageContext.id}
          languageCode={pageContext.languageCode}
          languagePrefix={pageContext.languagePrefix}
          {...pageContext.headerProps}
        />
      ) : null}
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.contactsIntroProps ? (
        <ContactsIntro {...context.contactsIntroProps} />
      ) : null}
      {context.contactsFormProps ? (
        <ContactsForm
          {...(context.contactsFormProps ? context.contactsFormProps : null)}
          onContactsFormSendToBackend={async (data) =>
            await sendFormToBackend(data)
          }
        />
      ) : null}
      <Separator />
      <Footer
        languageCode={pageContext.languageCode}
        languagePrefix={pageContext.languagePrefix}
      />
      <StructuredData languageCode={pageContext.languageCode} />
    </Container>
  )
})

const Container = styled.main``
