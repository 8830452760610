import styled from '@emotion/styled'
import { Checkbox } from 'app/components/Common/Checkbox'
import { FormMessages } from 'app/components/Common/FormMessages'
import { Input } from 'app/components/Common/Input'
import { Select } from 'app/components/Common/Select'
import { Spinner } from 'app/components/Common/Spinner'
import { Textarea } from 'app/components/Common/Textarea'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import {
  ContactsFormSenderBackend,
  FormData,
} from 'app/utils/ContactsFormSender'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import { isLeft } from 'fp-ts/lib/These'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i

export interface Props {
  title?: string
  description?: string
  languageCode: string
  languagePrefix: string
  onContactsFormSendToBackend: ContactsFormSenderBackend
  roomsList?: string[]
}

export const ContactsForm = memo(function ContactsForm({
  title,
  description,
  languageCode,
  languagePrefix,
  onContactsFormSendToBackend,
  roomsList,
}: Props) {
  /*
   * Form data
   */
  const [isSendingForm, setIsSendingForm] = useState(false)
  const [displaySendErrorFeedback, setDisplaySendErrorFeedback] =
    useState(false)
  const [displaySendConfirmFeedback, setDisplaySendConfirmFeedback] =
    useState(false)

  const onValidSubmission = useCallback(
    async (data: FormData) => {
      if (isSendingForm) {
        return
      }

      setDisplaySendErrorFeedback(false)
      setDisplaySendConfirmFeedback(false)
      setIsSendingForm(true)
      const sendResult = await onContactsFormSendToBackend(data)
      setIsSendingForm(false)

      if (isLeft(sendResult)) {
        setDisplaySendErrorFeedback(true)
      } else {
        setDisplaySendConfirmFeedback(true)

        typeof window.gtag !== 'undefined' &&
          window.gtag('event', 'submit', {
            event_category: 'Website',
            event_label: 'Contacts Form',
          })
      }
    },
    [isSendingForm, onContactsFormSendToBackend],
  )

  const { register, formState, handleSubmit } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: true,
  })

  const onSubmit = useMemo(
    () => handleSubmit(onValidSubmission),
    [handleSubmit, onValidSubmission],
  )

  /**
   * Handle scrolling to first field with errors
   */

  useEffect(() => {
    if (formState.errors) {
      const firstErrorElement = Object.values(formState.errors).find(
        (fieldError) => fieldError?.ref !== undefined,
      )?.ref as HTMLElement | undefined

      if (firstErrorElement && firstErrorElement.scrollIntoView) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [formState.errors])

  return (
    <Container>
      <Form
        onSubmit={isSendingForm ? undefined : onSubmit}
        noValidate
        autoComplete={'off'}
      >
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
        <Spinner className={isSendingForm ? '' : 'hidden'} />
        <Wrapper row wrap space="between">
          <Field>
            <Label>{`${useVocabularyData('name', languageCode)} *`}</Label>
            <Input
              error={formState.errors.name !== undefined}
              isDirty={formState.dirtyFields.name}
              type="text"
              required={true}
              {...register('name', {
                required: true,
                validate: (name) => name.length > 2,
              })}
            />
          </Field>
          <Field>
            <Label>{`${useVocabularyData('lastname', languageCode)} *`}</Label>
            <Input
              error={formState.errors.lastname !== undefined}
              type="text"
              required={true}
              {...register('lastname', {
                required: true,
                validate: (lastname) => lastname.length > 2,
              })}
            />
          </Field>
          <Field>
            <Label>{`${useVocabularyData('email', languageCode)} *`}</Label>
            <Input
              error={formState.errors.email !== undefined}
              isDirty={formState.dirtyFields.email}
              type="email"
              required={true}
              {...register('email', {
                required: true,
                validate: (email) => EMAIL_REGEX.test(email),
              })}
            />
          </Field>
          <Field>
            <Label>{useVocabularyData('telephone', languageCode)}</Label>
            <Input type="text" {...register('phone')} />
          </Field>
          {roomsList ? (
            <Field className="large-type">
              <Label>{useVocabularyData('room', languageCode)}</Label>
              <Select
                options={[
                  {
                    value: '',
                    hidden: true,
                  },
                  ...roomsList.map((item) => ({ value: item })),
                ]}
                {...register('room')}
              />
            </Field>
          ) : null}
          <Field>
            <Label>{useVocabularyData('adults', languageCode)}</Label>
            <Select
              options={[
                { value: '', hidden: true },
                { value: '1' },
                { value: '2' },
                { value: '3' },
                { value: '4' },
                { value: '5' },
                { value: '5+' },
              ]}
              {...register('adults')}
            />
          </Field>
          <Field>
            <Label>{useVocabularyData('childs', languageCode)}</Label>
            <Select
              options={[
                { value: '', hidden: true },
                { value: '0' },
                { value: '1' },
                { value: '2' },
                { value: '3' },
                { value: '4' },
                { value: '4+' },
              ]}
              {...register('childs')}
            />
          </Field>
          <Field className="large-type">
            <Label>{useVocabularyData('message', languageCode)}</Label>
            <Textarea required={false} {...register('message')} />
          </Field>
        </Wrapper>
        <Checkboxes>
          <Checkbox
            error={formState.errors.privacy_policy !== undefined}
            label={useVocabularyData('privacy-policy-text', languageCode)}
            required={true}
            {...register('privacy_policy', {
              required: true,
            })}
          />
        </Checkboxes>
        {displaySendErrorFeedback ? (
          <FormMessages
            text={useVocabularyData('form-error-message', languageCode)}
            title={useVocabularyData('form-error-message-title', languageCode)}
            type="error"
          />
        ) : null}
        {displaySendConfirmFeedback ? (
          <FormMessages
            text={useVocabularyData('form-confirmation-message', languageCode)}
            title={useVocabularyData(
              'form-confirmation-message-title',
              languageCode,
            )}
          />
        ) : null}
        <Input
          name="submit"
          type="submit"
          value={useVocabularyData('send-request', languageCode)}
          variant="submit"
        />
      </Form>
    </Container>
  )
})

const Container = styled.section`
  margin: 7.5rem auto;
  padding: 0 16.528vw 6rem;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    width: calc(100% - 33.056vw);
    height: 100%;
    border: 3px solid #edf4f1;
    position: absolute;
    top: 1.125rem;
    left: 16.528vw;
    z-index: -1;
  }

  @media (max-width: 1199px) {
    padding-right: 1.9375rem;
    padding-left: 1.9375rem;
    &:before {
      width: 100%;
      border-right: 0;
      border-left: 0;
      left: 0;
    }
  }

  @media (max-width: 1023px) {
    margin: 5.625rem auto 3.75rem;
    padding-bottom: 0;
    &:before {
      display: none;
    }
  }
`

const Form = styled.form`
  width: 100%;
  padding: 0 8.194vw;
  position: relative;

  @media (max-width: 1440px) {
    padding: 0 6.194vw;
  }

  @media (max-width: 1199px) {
    padding-right: 0;
    padding-left: 0;
  }
`

const Title = styled.div`
  display: inline-block;
  max-width: 45vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 2.4375rem;
  margin: auto;
  padding: 0 5.806vw;

  @media (max-width: 1199px) {
    padding-right: 1.9375rem;
    padding-left: 1.9375rem;
  }

  @media (max-width: 1023px) {
    max-width: none;
    font-size: 25px;
    padding: 0;
  }
`

const Description = styled.div`
  max-width: 430px;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 1.75rem;
  margin: 1.25rem auto 0;

  @media (max-width: 1023px) {
    margin-top: 0.75rem;
  }
`

const Wrapper = styled(FlexBox)`
  margin-top: 2.25rem;
  text-align: left;

  @media (max-width: 1023px) {
    margin-top: 1.875rem;
  }
`

const Field = styled.div`
  width: calc(50% - 0.75rem);
  margin-top: 1.75rem;
  &.small-type {
    width: calc(25% - 0.75rem);
  }
  &.large-type {
    width: 100%;
  }

  @media (max-width: 1023px) {
    width: calc(50% - 0.375rem);
    &.small-type {
      width: calc(50% - 0.375rem);
    }
  }

  @media (max-width: 424px) {
    width: 100%;
    &.small-type {
      width: 100%;
    }
  }
`

const Label = styled.label`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  line-height: 0.875rem;
  margin-bottom: 0.375rem;
  text-transform: uppercase;
`

const Checkboxes = styled.div`
  text-align: center;
`
