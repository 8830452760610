import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { memo, useEffect, useState } from 'react'
import { Check, X } from 'react-feather'

export interface Props {
  text?: string
  title: string
  type?: 'confirm' | 'error'
}

export const FormMessages = memo(function FormMessages({
  text,
  title,
  type = 'confirm',
}: Props) {
  const [openMessageModal, setMessageModalOpen] = useState(false)
  const [buttonCloseVisible, setButtonClose] = useState(true)

  useEffect(() => {
    setButtonClose(true)

    if (buttonCloseVisible) {
      setMessageModalOpen(true)
    }
  }, [buttonCloseVisible])

  return (
    <Container
      className={`confirmation-message${openMessageModal ? ' opened' : ''}`}
      onClick={() => {
        setMessageModalOpen(false)
      }}
    >
      <Wrapper onClick={(e) => e.stopPropagation()}>
        <Close
          onClick={() => {
            setMessageModalOpen(false)
          }}
        />
        <SVG className={type === 'error' ? 'error' : undefined} dial={5}>
          {type === 'error' ? <X /> : <Check />}
        </SVG>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        {text ? <Text dangerouslySetInnerHTML={{ __html: text }} /> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.3s;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
  }
  &.opened {
    opacity: 1;
    visibility: visible;
    z-index: 99;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 650px;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: default;
  padding: 4.625rem;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
`

const SVG = styled(FlexBox)`
  width: 2.25rem;
  height: 2.25rem;
  background: #53a78b;
  border-radius: 50%;
  margin: 0 auto 0.75rem;
  &.error {
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
  }
  svg {
    width: auto;
    height: 1.25rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 2;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.875rem;
  margin-bottom: 0.75rem;
`

const Text = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
`

const Close = styled.div`
  width: 28px;
  height: 28px;
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 10px;
  z-index: 2;
  transform: translateX(-50%);
  &:before,
  &:after {
    content: '';
    width: 2px;
    height: 28px;
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
    position: absolute;
    top: 0;
    left: 13px;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`
