import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

export interface Props {
  error?: boolean
  name: string
  label?: string
  required?: boolean
  onBlur?: FocusEventHandler<HTMLInputElement>
  onChange?: ChangeEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
}

export const Checkbox = memo(
  forwardRef<HTMLInputElement, Props>(function Checkbox(
    { error = false, name, label, required, onBlur, onChange, onFocus },
    ref,
  ) {
    const id = `#form-${name}`

    return (
      <Container dial={4} inline row>
        <InputField
          ref={ref}
          id={id}
          name={name}
          type="checkbox"
          required={required}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
        />
        {label ? (
          <Label
            error={error}
            htmlFor={id}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        ) : null}
      </Container>
    )
  }),
)
Checkbox.displayName = 'Checkbox'

const Container = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  line-height: 1.875rem;
  margin: 2.5rem auto 0;
  position: relative;
`

const InputField = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;

  &:checked ~ label {
    &:before {
      background: ${({ theme }) => theme.colors.variants.neutralDark4};
    }
    &:after {
      opacity: 1;
    }
  }
`

const Label = styled.label<ContainerProps>`
  padding-left: 30px;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 10px;

  &:before,
  &:after {
    content: '';
    border-radius: 50%;
    position: absolute;
  }
  &:before {
    width: 18px;
    height: 18px;
    border: 1px solid
      ${({ error, theme }) =>
        error ? theme.colors.variants.dangerLight1 : '#EDF4F1'};
    background: rgba(237, 244, 241, 0.5);
    top: 6px;
    left: 0;
  }
  &:after {
    width: 8px;
    height: 8px;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    top: 11px;
    left: 5px;
    opacity: 0;
  }

  a {
    font-weight: 600;
    text-decoration: underline;
  }
`

interface ContainerProps {
  error: boolean
}
