import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'
import { Mail, MapPin, Phone } from 'react-feather'

export interface Props {
  address?: string
  email?: string
  languageCode: string
  phone?: string
  title?: string
}

export const ContactsIntro = memo(function ContactsIntro({
  address,
  email,
  languageCode,
  phone,
  title,
}: Props) {
  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      <Contacts space="between" align="center" row wrap>
        {address ? (
          <Address>
            <MapPin />
            <Wrap>
              <Label>{useVocabularyData('address', languageCode)}</Label>
              <Field dangerouslySetInnerHTML={{ __html: address }} />
            </Wrap>
          </Address>
        ) : null}
        {phone ? (
          <Anchor
            href={`tel:${phone}`}
            onClick={() => {
              if (isMobile) {
                typeof window.gtag !== 'undefined' &&
                  window.gtag('event', 'Click', {
                    event_category: 'Website',
                    event_label: 'Mobile Phone',
                  })
              }
            }}
          >
            <Phone />
            <Wrap>
              <Label>{useVocabularyData('telephone', languageCode)}</Label>
              <Field className="contact-field">{phone}</Field>
            </Wrap>
          </Anchor>
        ) : null}
        {email ? (
          <Anchor
            href={`mailto:${email}`}
            target="_blank"
            onClick={() => {
              typeof window.gtag !== 'undefined' &&
                window.gtag('event', 'Click', {
                  event_category: 'Website',
                  event_label: 'Email Address',
                })
            }}
          >
            <Mail />
            <Wrap>
              <Label>{useVocabularyData('email', languageCode)}</Label>
              <Field className="contact-field">{email}</Field>
            </Wrap>
          </Anchor>
        ) : null}
      </Contacts>
    </Container>
  )
})

const Container = styled.section`
  margin: 7.5rem auto 10rem;
  padding: 0 1.875rem;
  text-align: center;

  @media (max-width: 1023px) {
    margin: 6rem auto 7.5rem;
    padding: 0 1.5rem;
  }

  @media (max-width: 767px) {
    margin: 3.75rem auto;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 3.0625rem;
  text-align: center;

  @media (max-width: 1023px) {
    font-size: 30px;
  }
`

const Contacts = styled(FlexBox)`
  max-width: 950px;
  margin: 38px auto 0;

  @media (max-width: 1023px) {
    max-width: 750px;
  }
`

const Style = css`
  display: flex;
  align-items: flex-start;
  margin: 0 1.5rem;
  text-align: left;

  svg {
    width: auto;
    height: 2.125rem;
    margin-right: 1.25rem;
    stroke-width: 1;
    stroke: #53a78b;
    margin-top: 3px;
  }

  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
    margin-top: 1.5rem;
    text-align: center;
    svg {
      display: none;
    }
  }
`

const Address = styled.div`
  ${Style}

  @media (max-width: 767px) {
    margin-top: 0;
  }
`

const Anchor = styled.a`
  &:hover {
    .contact-field {
      text-decoration: underline;
    }
  }

  ${Style}
`

const Wrap = styled.span``

const Label = styled.label`
  display: block;
  color: #53a78b;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 0.9375rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`

const Field = styled.span`
  display: block;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 28px;
`
